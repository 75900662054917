<template>

	<div class="grid p-fluid">
		<div class="col-12 lg:col-9">
			<div class="card">
				<h5>Linear Chart</h5>
				<Chart type="line" :data="lineData" :options="lineOptions" />
			</div>
			<!-- <div class="card flex flex-column align-items-center">
				<h5 class="align-self-start">Pie Chart</h5>
				<Chart type="pie" :data="pieData" :options="pieOptions" style="width: 50%" />
			</div>

			<div class="card  flex flex-column align-items-center">
				<h5 class="align-self-start">Polar Area Chart</h5>
				<Chart type="polarArea" :data="polarData" :options="polarOptions" style="width: 50%" />
			</div> -->
		</div>
		<div class="col-12 lg:col-3">
			<div class="card">
				<div class="flex justify-content-start flex-wrap card-container blue-container">
					<div
						class="flex align-items-center justify-content-center w-6rem h-4rem font-bold text-white border-round m-2 ml-0">
						<span class="p-float-label">
							<InputText id="maxShow" type="text" v-model="maxShow" />
							<label for="maxShow">幅值</label>
						</span>
					</div>
					<div
						class="flex align-items-center justify-content-center w-6rem h-4rem font-bold text-white border-round m-2 ml-0">
						<span class="p-float-label">
							<InputText id="ultraDist" type="text" v-model="ultraDist" />
							<label for="ultraDist">测距(mm)</label>
						</span>
					</div>
					<div
						class="flex align-items-center justify-content-center w-6rem h-4rem font-bold text-white border-round m-2 ml-0">
						<span class="p-float-label">
							<InputText id="ultraCount" type="text" v-model="ultraCount" />
							<label for="ultraCount">采样点数</label>
						</span>
					</div>
					<div
						class="flex align-items-center justify-content-center w-7rem h-4rem font-bold text-white border-round m-2 ml-0">
						<span class="p-float-label">
							<InputText id="ultraCalc" type="text" v-model="ultraCalc" />
							<label for="ultraCalc">计算距离(mm)</label>
						</span>
					</div>
				</div>
				<Textarea v-model="oscData" rows="27" cols="30" />
				<div class="flex justify-content-start flex-wrap card-container blue-container mt-2 mb-1">
					<Button label="绘制图形" @click="handleClick($event)" />
				</div>
			</div>
			<!-- <div class="card">
				<h5>Bar Chart</h5>
				<Chart type="bar" :data="barData" :options="barOptions" />
			</div>

			<div class="card flex flex-column align-items-center">
				<h5 class="align-self-start">Doughnut Chart</h5>
				<Chart type="doughnut" :data="pieData" :options="pieOptions" style="width: 50%" />
			</div>

			<div class="card flex flex-column align-items-center">
				<h5 class="align-self-start">Radar Chart</h5>
				<Chart type="radar" :data="radarData" :options="radarOptions" style="width: 50%" />
			</div> -->
		</div>
	</div>
</template>

<script>
import EventBus from '@/AppEventBus';

export default {
	themeChangeListener: null,
	mounted() {
		this.themeChangeListener = (event) => {
			if (event.dark)
				this.applyDarkTheme();
			else
				this.applyLightTheme();
		};
		EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
			this.applyDarkTheme();
		}
		else {
			this.applyLightTheme();
		}
	},
	beforeUnmount() {
		EventBus.off('change-theme', this.themeChangeListener);
	},
	data() {
		return {
			lineData: {
				labels: ['1', '2', '3', '4', '5', '6', '7'],
				datasets: [
					{
						label: 'Osc Dataset',
						data: [65, 59, 80, 81, 56, 55, 40],
						fill: false,
						backgroundColor: '#2f4860',
						borderColor: '#2f4860',
						tension: .1
					}
				]
			},
			lineOptions: null,
			oscData: null,
			maxShow: null,
			ultraDist: null,
			ultraCount: null,
		}
	},
	methods: {
		handleClick(event) {
			console.log(event);
			console.log(this.oscData);

			if (this.oscData == null)
				return;

			let _data = this.oscData;
			_data = _data.replace(/\r\n/g, ' ');
			_data = _data.replace(/\n/g, ' ');
			_data = _data.replace(/ +/g, ' ');
			_data = _data.trim();

			let arr = _data.split(' ')
			let dist = arr.slice(9, 11)
			let arrdata = arr.slice(37, -3)
			let arrlabel = [...Array(arrdata.length).keys()];

			this.lineData.datasets[0].data = arrdata.map(e => {
				return parseInt(e, 16);
			})
			this.lineData.labels = arrlabel

			for (let i = 23; i < this.lineData.datasets[0].data.length; i++) {
				if (this.lineData.datasets[0].data[i] > 100) {
					this.ultraCalc = (i * 50 + 1322) * 0.174
					break
				}
			}

			this.maxShow = Math.max(...this.lineData.datasets[0].data)
			this.ultraDist = parseInt(dist[0], 16) * 256 + parseInt(dist[1], 16)
			this.ultraCount = this.lineData.datasets[0].data.length;
		},
		isDarkTheme() {
			return this.$appState.darkTheme === true;
		},
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color: '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color: '#ebedef',
						}
					},
				}
			};

		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color: 'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color: 'rgba(160, 167, 181, .3)',
						}
					},
				}
			};

		}
	}
}
</script>